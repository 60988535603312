<template>
  <div class="form">
    <div class="wrapper" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
      <Backlink title="select_banks" service="mortgage" backlink="/mortgage/reg-payment" />
      <div class="content">
        <div class="content__container">
          <div class="bank__sort">
            <v-select
              v-model="sort"
              item-text="name"
              item-value="value"
              class="select__dropdown"
              :items="getList('banks_select_1_value_', 4)"
              :hint="'sort' | localize"
              append-icon="icon-arrow-down"
              persistent-hint
            ></v-select>
          </div>
          <div class="bank__container">
            <div class="bank" v-for="(bank, k) in bankSorted" :key="k">
              <p class="bank__caption" :class="{bank__caption_best: isBestBank(bank.id)}">
                <span class="icon-bank"></span>
                {{ 'bank' | localize }} {{ k + 1 }}
              </p>
              <div class="bank__item" :class="{bank__item_best: isBestBank(bank.id)}">
                <div v-for="(program, k) in bank.programs" :key="k">
                  <div class="bank__item_title">{{ program.name }}</div>
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_sum" | localize }}
                    </div>
                    <div class="bank__row_text">
                      {{ program.amount.toLocaleString() }}&nbsp;<span class="icon-₪"></span>
                    </div>
                  </div>
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_percent" | localize }}
                    </div>
                    <div class="bank__row_text">
                      {{ program.percent.toLocaleString() }} %
                    </div>
                  </div>
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_month" | localize }}
                    </div>
                    <div class="bank__row_text">{{ program.months }}</div>
                  </div>
                  <div class="bank__line" v-if="k+1 < bank.programs.length"></div>
                </div>
                <div class="bank__bottom">
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_payment" | localize }}
                    </div>
                    <div class="bank__row_text">
                      {{ bank && bank.payment ? bank.payment.toLocaleString() : '-' }}&nbsp;<span class="icon-₪"></span>
                    </div>
                  </div>
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_mortgage_sum" | localize }}
                    </div>
                    <div class="bank__row_text">
                      {{ bank && bank.return ? bank.return.toLocaleString() : '-' }}&nbsp;<span class="icon-₪"></span>
                    </div>
                  </div>
                  <button class="button" @click="registration">
                    {{ "select" | localize }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>

          <div class="error__container">
            <p class="error" v-if="error">{{ error | localize }}</p>
            <p v-else class="info info_3">
              {{ "bank_text_footer" | localize }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="750">
          <template v-slot:activator="{}"></template>
          <v-card>
            <div class="dialog__modal__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <button class="close dialog__modal_close" @click="closeDialog('dialog')"></button>
            <Registration class="dialog__modal" :correctPhone="correctPhone" @confirmed="onUserConfirmed" modal />
          </v-card>
        </v-dialog>
      </div>
    </template>

    <template>
      <div class="text-center">
        <v-dialog v-model="dialogCode" width="750">
          <template v-slot:activator="{}"></template>
          <v-card>
            <div class="dialog__modal__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <button class="close dialog__modal_close" @click="closeDialog('dialogCode')"></button>
            <Changes class="dialog__modal" modal @goToPhone="goToPhone" @confirmed="onUserPhoneConfirmed" :nextlink="{ name: 'profile', params: { service: 'mortgage' }}" />
          </v-card>
        </v-dialog>
      </div>
    </template>

    <PopupText />
    <PopupLogin />
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import PopupText from '@/components/PopupText.vue'
import PopupLogin from '@/components/PopupLogin.vue'
import Registration from '@/components/Registration'
import Changes from '@/components/profile/Changes'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Mortgage_bank_offers',
  components: { Backlink, Registration, PopupText, PopupLogin, Changes },
  props: { returnToLogin: { type: Boolean, default: false } },
  mixins: [commonMixin],
  data: () => ({
    sort: null,
    error: null,
    banks: [],
    userServiceId: null,
    userId: null,
    dialog: false,
    dialogCode: false,
    correctPhone: false
  }),
  created () {
    this.userId = this.$store.state.user ? this.$store.state.user.id : 0

    if (this.returnToLogin) {
      if (this.isMobile) {
        this.$store.commit('togglePopup')
        this.$store.commit('togglePopup')
      } else {
        this.dialog = true
      }
    } else {
      this.$store.commit('togglePopupText', { id: 13, withTitle: false, type: 'popup_info' })
    }
  },
  mounted () {
    const mortgage = this.$cookies.get('mortgage') || {}

    this.$store.commit('loading', true)
    this.axios.get('user_service', {
      params: {
        user_id: this.userId,
        service_id: this.$store.state.services.mortgage
      }
    }).then((response) => {
      this.userServiceId = response.data.id || 0
      this.axios.get('program/select', {
        params: {
          user_service_id: this.userServiceId,
          price: mortgage.price,
          capital: mortgage.capital
        }
      }).then((response) => {
        this.banks = response.data
        this.$store.commit('loading', false)
      }).catch((error) => {
        this.$store.commit('loading', false)
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }).catch((error) => {
      this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
      this.$store.commit('loading', false)
      this.$scrollTo('.error__container', 300, { offset: -400 })
    })
  },
  methods: {
    registration () {
      if (this.isMobile) {
        this.$store.commit('togglePopup')
      } else {
        this.dialog = true
      }
    },
    onUserConfirmed () {
      this.dialog = false
      this.dialogCode = true
    },
    onUserPhoneConfirmed () {
      this.dialogCode = false
    },
    goToPhone () {
      this.correctPhone = true
      this.dialogCode = false
      this.dialog = true
    }
  }
}
</script>
